import About from './components/00_about/About';
import Authenticity from './components/01_authenticity/Authenticity';
import Features from './components/02_features/Features';
import Accommodation from './components/03_accommodation/Accommodation';
import Offers from './components/04_offers/Offers';
import MiceSection from './components/05_mice/MiceSection';
import Wellness from './components/06_wellness/Wellness';
import Glance from './components/08_glance/Glance';
import Constants from '../../utils/Constants';
import HeroSlideshow from '../../components/03_heroSlideshow/HeroSlideshow';
import { useEffect } from 'react';
import { CapitalizeFirstLeter } from '../../utils/CapitalizeFirstLetter';
import Destinations from './components/09_destinations/Destinations';
import PostBar from './components/001_postBar/PostBar';

const Home = () => {
  useEffect(() => {
    document.title = `${CapitalizeFirstLeter(Constants.ACCOMMODATIONS_NAME)}`;
  }, []);

  return (
    <div id={Constants.HOME} className='home'>
      <div className='home_container_hero'>
        <HeroSlideshow />
        <div className='home_container_hero_container'></div>
        <div className='home_container_hero_button'>
          <a
            href={Constants.bookNowLink}
            target='_blank'
            rel='noreferrer'
            className='opacity_transition_low'
          >
            <div>RESERVE NOW</div>
          </a>
        </div>
      </div>
      <h1 hidden>{CapitalizeFirstLeter(Constants.ACCOMMODATIONS_NAME)}</h1>
      <About />
      <Authenticity />
      <PostBar />
      <MiceSection />
      <Features />
      <Accommodation />
      <Offers />
      <Wellness />
      <Destinations />
      <Glance />
    </div>
  );
};

export default Home;
